import React, { useMemo, useContext } from "react";
import { IntersectionContext } from "../layout/intersection";
import { motion, AnimatePresence } from "framer-motion";

export const AppearProject = ({
  children,
  delayOrder = 0.2, // order of appearance
  duration = 0.3,
  easing = "linear" // [number, number, number, number] | "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate" | EasingFunction;
}) => {
  const { inView } = useContext(IntersectionContext);
  const transition = useMemo(
    () => ({
      duration,
      delay: delayOrder,
      ease: easing,
      staggerChildren: 0.4
    }),
    [duration, delayOrder, easing]
  );



  return (
    <AnimatePresence>
    <motion.div
      initial="fade"
      animate={inView ? "show" : "hidden"}
      exit="exit"
    >
      {children}
    </motion.div>
    </AnimatePresence>
  );
};