import React, {useEffect, useState} from "react"
import {IntersectionContext} from "./layout/intersection"
import {useIntersection} from "react-use"

const ShowReel = () => {
    const [inView, setInView] = useState(false)
    const intersectionRef = React.useRef(null)
    const intersection = useIntersection(intersectionRef, {
        threshold: 0,
    })

    /*useEffect(() => {
        const video = document.getElementById('showreel')

        function toggleMute(){
            console.log('toggle mute', video)
            video.muted = false
        }

        video.addEventListener('playing', toggleMute)
    }, [])*/

    useEffect(() => {
        setInView(intersection?.isIntersecting)
        console.log('intersection changed', intersection, intersectionRef)
        const video = document.getElementById('showreel')
        if( video && !intersection?.isIntersecting ) {
            video.pause()
        } /*else if( video && intersection?.isIntersecting ) {
            video.play()
        }*/
    }, [intersection])

    return (
        <IntersectionContext.Provider value={{ inView }}>
            <video ref={intersectionRef} poster={"/uploads/showreel-poster.jpeg"} id={"showreel"} playsInline className={"absolute inset-0 h-screen w-full object-contain md:object-cover z-110"} controls>
                <source type={"video/mp4"} src={"/uploads/TACTILE_SHOWREEL.mp4"} />
            </video>
        </IntersectionContext.Provider>
    )
}

export default ShowReel
