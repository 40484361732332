import React, { useState, useEffect, useRef } from 'react';
import Link from "gatsby-link" // not error 

import SEO from "../components/seo"
import MainTitle from "../components/layout/MainTitle"
import { motion, useSpring, useTransform, AnimatePresence, useMotionTemplate, useViewportScroll } from 'framer-motion';
import { IntersectionObserver } from "../components/layout/intersection";
import { AppearAnimText } from "../components/animations/text_fade";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Linkwrapper from "../components/linkWrapper/linkWrapper";
import FooterLayout from "../components/footer/footer";
import { graphql } from "gatsby";
import { WritingObserver } from "../components/animations/writing";
import WebglWrapperHome from "../components/webgl/curtain/wrapper_home";
import Smoothscroll from "../components/Smoothscroll/smoothscroll";
import { isBrowser, isMobile } from "react-device-detect";
import Img from "gatsby-image/withIEPolyfill";
import ReactPlayer from 'react-player/lazy';
import VideoProjects from '../images/content/homepage/MiniShowreel.mp4';
import VideoTeam from '../images/content/homepage/TactileTeam.mp4';
import ShowReel from "../components/showreel";

const animation = {
  hidden: {
    opacity: 0, y: 50,
    transition: {
      type: 'tween',
      duration: 0.2,
    }
  },
  show: {
    opacity: 1, y: 0,
    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 50,
      duration: 0.5,
    }
  }
}

const IndexPage = ({ scrollHeight, data }) => {

  const [WindowHeight, setWindowHeight] = useState(0);
  const [WindowWidth, setWindowWidth] = useState(0);
  const [WindowScope, setWindowScope] = useState();
  const [isHoverLink1, setIsHoverLink1] = useState(false);
  const [isHoverLink2, setIsHoverLink2] = useState(false);

  const ref = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window
    setWindowScope(windowGlobal);

    function setValuesWindow() {
      setWindowHeight(windowGlobal.innerHeight);
      setWindowWidth(windowGlobal.innerWidth);
    };

    windowGlobal.addEventListener('resize', setValuesWindow);
    setValuesWindow();
    return () => windowGlobal.removeEventListener('resize', setValuesWindow);
  }, [WindowHeight]);

  const { scrollYProgress, scrollY } = useViewportScroll();

  let WindowHeightValue = WindowHeight;

  function toogleHover1(value) {
    setIsHoverLink1(!value);
  }

  function toogleHover2(value) {
    setIsHoverLink2(!value);
  }

  // let WindowWidthValue = WindowWidth;
  // let XPosition = WindowScope !== undefined && WindowScope.clientX/WindowWidthValue*100;
  // let YPosition = WindowScope !== undefined && WindowScope.clientY/WindowHeightValue*100;

  // console.log(XPosition, YPosition);

  //numberofblocsSpace
  let BlocSpace = isBrowser ? 13 : 7;

  //title
  const displayValueTitle = useTransform(scrollY, [0, 100, WindowHeightValue / 2], [1, 1, 0]);
  const yValueTitle = useTransform(scrollY, [0, 100, WindowHeightValue / 2], [0, 0, -WindowHeightValue]);
  const YPositionValueSpringTitle = useSpring(yValueTitle, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  //backgroundGray
  const displayValueBg = useTransform(scrollY, [0, WindowHeightValue, WindowHeightValue * (BlocSpace - 2), WindowHeightValue * (BlocSpace - 1)], [1, 1, 1, 0]);

  //bloc 1 presentation
  const displayValueBloc1 = useTransform(scrollY, [0, WindowHeightValue * 1, WindowHeightValue * 2, WindowHeightValue * 3], [0, 1, 1, 0]);
  const YPositionValueBloc1 = useTransform(scrollY, [0, WindowHeightValue * 1, WindowHeightValue * 2, WindowHeightValue * 3], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc1 = useSpring(YPositionValueBloc1, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })
  // mobile
  const displayValueBloc1Mobile = useTransform(scrollY, [0, WindowHeightValue * 1, WindowHeightValue * 1.5, WindowHeightValue * 2], [0, 1, 1, 0]);
  const YPositionValueBloc1Mobile = useTransform(scrollY, [0, WindowHeightValue * 1, WindowHeightValue * 1.5, WindowHeightValue * 2], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc1Mobile = useSpring(YPositionValueBloc1Mobile, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  //bloc 2 presentation
  const displayValueBloc2 = useTransform(scrollY, [WindowHeightValue * 2, WindowHeightValue * 3, WindowHeightValue * 4, WindowHeightValue * 5], [0, 1, 1, 0]);
  const YPositionValueBloc2 = useTransform(scrollY, [WindowHeightValue * 2, WindowHeightValue * 3, WindowHeightValue * 4, WindowHeightValue * 5], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc2 = useSpring(YPositionValueBloc2, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })
  // mobile
  const displayValueBloc2Mobile = useTransform(scrollY, [WindowHeightValue * 1.5, WindowHeightValue * 1.75, WindowHeightValue * 2.25, WindowHeightValue * 2.5], [0, 1, 1, 0]);
  const YPositionValueBloc2Mobile = useTransform(scrollY, [WindowHeightValue * 1.5, WindowHeightValue * 1.75, WindowHeightValue * 2.25, WindowHeightValue * 2.5], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc2Mobile = useSpring(YPositionValueBloc2Mobile, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  //bloc 3 presentation
  const displayValueBloc3 = useTransform(scrollY, [WindowHeightValue * 4, WindowHeightValue * 5, WindowHeightValue * 6, WindowHeightValue * 7], [0, 1, 1, 0]);
  const YPositionValueBloc3 = useTransform(scrollY, [WindowHeightValue * 4, WindowHeightValue * 5, WindowHeightValue * 6, WindowHeightValue * 7], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc3 = useSpring(YPositionValueBloc3, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })
  //mobile
  const displayValueBloc3Mobile = useTransform(scrollY, [WindowHeightValue * 2.25, WindowHeightValue * 2.5, WindowHeightValue * 3, WindowHeightValue * 3.25], [0, 1, 1, 0]);
  const YPositionValueBloc3Mobile = useTransform(scrollY, [WindowHeightValue * 2.25, WindowHeightValue * 2.5, WindowHeightValue * 3, WindowHeightValue * 3.25], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc3Mobile = useSpring(YPositionValueBloc3Mobile, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  //bloc 4 presentation
  const displayValueBloc4 = useTransform(scrollY, [WindowHeightValue * 6, WindowHeightValue * 7, WindowHeightValue * 8, WindowHeightValue * 9], [0, 1, 1, 1]);
  const YPositionValueBloc4 = useTransform(scrollY, [WindowHeightValue * 6, WindowHeightValue * 7, WindowHeightValue * 8, WindowHeightValue * 9, WindowHeightValue * 10], [WindowHeightValue, 0, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc4 = useSpring(YPositionValueBloc4, { damping: 15, stiffness: 10, mass: 0.2 })
  //mobile
  const displayValueBloc4Mobile = useTransform(scrollY, [WindowHeightValue * 3.25, WindowHeightValue * 3.75, WindowHeightValue * 4.25, WindowHeightValue * 4.5], [0, 1, 1, 1]);
  const YPositionValueBloc4Mobile = useTransform(scrollY, [WindowHeightValue * 3.25, WindowHeightValue * 3.75, WindowHeightValue * 4.25, WindowHeightValue * 4.5], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionValueSpringBloc4Mobile = useSpring(YPositionValueBloc4Mobile, { damping: 15, stiffness: 10, mass: 0.2 })

  //bloc 5 presentation
  const clipPathValue = useTransform(scrollY, [0, WindowHeightValue * 8, WindowHeightValue * 9], [0, 0, 150]);
  const clipPathValueSpring = useSpring(clipPathValue, { damping: 15, stiffness: 10, mass: 0.2 })
  const clipPathString = useMotionTemplate`circle(${clipPathValueSpring}% at 50% 50%)`;
  //mobile
  const clipPathValueMobile = useTransform(scrollY, [0, WindowHeightValue * 4.5, WindowHeightValue * 4.75], [0, 0, 150]);
  const clipPathValueSpringMobile = useSpring(clipPathValueMobile, { damping: 15, stiffness: 10, mass: 0.2 })
  const clipPathStringMobile = useMotionTemplate`circle(${clipPathValueSpringMobile}% at 50% 50%)`;

  const YDisplayValueBloc5 = useTransform(scrollY, [WindowHeightValue * 7, WindowHeightValue * 7.5, WindowHeightValue * BlocSpace], [0, 1, 1]);
  const YDisplayValueBloc5Mobile = useTransform(scrollY, [0, WindowHeightValue * 4.5, WindowHeightValue * 4.75], [0, 1, 1]);

  const YPositionValueBloc5 = useTransform(scrollY, [WindowHeightValue * (BlocSpace - 1), WindowHeightValue * BlocSpace], [0, -WindowHeightValue * 1.5]);
  const YPositionValueSpringBloc5 = useSpring(YPositionValueBloc5, { damping: 100, stiffness: 40, mass: 0.2 })

  //mobile
  const YPositionValueBloc5Mobile = useTransform(scrollY, [WindowHeightValue * (BlocSpace - 1), WindowHeightValue * BlocSpace], [0, -WindowHeightValue * 1.5]);

  //opacityBlocEmotion
  const opacityBlocEmotion = useTransform(scrollY, [WindowHeightValue * 9.5, WindowHeightValue * 10.5], [1, 0]);
  const PositionBlocDesignerIdee = useTransform(scrollY, [WindowHeightValue * 10, WindowHeightValue * 11], [WindowHeightValue, 0]);
  const YPositionBlocDesignerIdee = useSpring(PositionBlocDesignerIdee, { damping: 15, stiffness: 10, mass: 0.2 })
  //mobile
  const opacityBlocEmotionMobile = useTransform(scrollY, [WindowHeightValue * 5.25, WindowHeightValue * 5.5], [1, 0]);
  const PositionBlocDesignerIdeeMobile = useTransform(scrollY, [WindowHeightValue * 5.5, WindowHeightValue * 5.75], [WindowHeightValue, 0]);
  const YPositionBlocDesignerIdeeMobile = useSpring(PositionBlocDesignerIdeeMobile, { damping: 15, stiffness: 10, mass: 0.2 })

  //forme video 1 
  const yvalueWaudescher = useTransform(scrollY, [0, WindowHeightValue * 2, WindowHeightValue * 5.5], [WindowHeightValue / 2, WindowHeightValue / 2, -(WindowHeightValue + 200)]);
  const yvalueSpringWaudescher = useSpring(yvalueWaudescher, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })
  //mobile
  const YPositionWaudescherMobile = useTransform(scrollY, [WindowHeightValue * 1.25, WindowHeightValue * 1.5, WindowHeightValue * 1.75, WindowHeightValue * 2], [WindowHeightValue, 0, 0, -WindowHeightValue]);
  const YPositionWaudescherSpringMobile = useSpring(YPositionWaudescherMobile, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  function hideCursor() {
    dispatch({ type: 'GetMouseState', value: 0 });
  }

  function showCursor() {
    dispatch({ type: 'GetMouseState', value: 1 });
  }

  const Transition = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 0.35,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        delay: 0,
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <SEO title="designers d’idées" />
      <div>{isBrowser && <ParallaxForms scrollYProgress={scrollYProgress} windowHeightValue={WindowHeightValue} scrollY={scrollY} data={data} />}</div>
      <IntroBtn scrollToContentValue={WindowHeightValue * (BlocSpace - 1)} windowHeightValue={WindowHeightValue} />
      <motion.div style={{ opacity: displayValueTitle, y: YPositionValueSpringTitle }} className="fixed flex justify-center items-center top-0 w-full z-40 h-screen">
        <div className="w-full flex items-center justify-center">
          <ShowReel />
        </div>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} style={{ opacity: isBrowser ? displayValueBloc1 : displayValueBloc1Mobile, y: isBrowser ? YPositionValueSpringBloc1 : YPositionValueSpringBloc1Mobile }} className="fixed top-0 w-full z-40 whitespace-no-wrap">
        <IntersectionObserver>
          <AppearAnimText>
            <MainTitle>
              <motion.p className="willChange" variants={animation}>Une agence</motion.p>
              <motion.p className="willChange" variants={animation}>humainement</motion.p>
              <motion.p className="willChange" variants={animation}>passionnée,</motion.p>
              <motion.p className="willChange" variants={animation}>émotionnellement</motion.p>
              <div className="w-full flex items-center justify-center -mt-4 md:-mt-8" ><div className="w-full md:mt-5 lg:mt-0 lg:w-3/4"><WritingObserver isReset={true} text="engagée" /></div></div>
            </MainTitle>
          </AppearAnimText>
        </IntersectionObserver>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} style={{ opacity: isBrowser ? displayValueBloc2 : displayValueBloc2Mobile, y: isBrowser ? YPositionValueSpringBloc2 : YPositionValueSpringBloc2Mobile }} className="fixed top-0 w-full z-40">
        <IntersectionObserver>
          <AppearAnimText>
            <MainTitle>
              <div className="w-full flex items-center justify-center -mb-8 md:-mb-12 xl:-mb-16 xxl:-mb-24 relative z-10">
                <div className="w-full md:mb-5 lg:mb-0 lg:w-3/4"><WritingObserver isReset={true} text="engagée" />
                </div>
              </div>
              <motion.p className="willChange relative z-20" variants={animation}>à saisir, révéler</motion.p>
              <motion.p className="willChange relative z-20" variants={animation}>et développer</motion.p>
              <motion.p className="willChange relative z-20" variants={animation}>vos plus</motion.p>
              <motion.div className="willChange relative z-20 underline inline-block" variants={animation}>
                <Link to="/projets/" onMouseEnter={() => toogleHover1(isHoverLink1)} onMouseLeave={() => toogleHover1(isHoverLink1)}>
                  <Linkwrapper mouseIcon="discover">belles histoires.</Linkwrapper>
                </Link>
              </motion.div>
              <div className="fixed transform mb-16 md:translate-y-0 h-40 w-auto md:mb-20 xl:w-64 xxl:w-70 xl:h-64 xxl:h-70 pointer-events-none bottom-0 right-0 xl:mr-32 xxl:mr-56 z-30">
                <AnimatePresence>
                  {isMobile &&
                    <motion.div
                      initial="initial"
                      animate="enter"
                      exit="exit"
                      className="absolute z-20 clippathProjects top-0 left-0 w-full h-full"
                      variants={Transition}
                    >
                      <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        controls={false}
                        muted={true}
                        url={VideoProjects} >
                      </ReactPlayer>
                    </motion.div>
                  }
                  {isHoverLink1 &&
                    <motion.div
                      initial="initial"
                      animate="enter"
                      exit="exit"
                      className="absolute z-20 clippathProjects top-0 right-0 w-full h-full"
                      variants={Transition}
                    >
                      <ReactPlayer
                        width='100%'
                        height='auto'
                        loop={true}
                        playing={true}
                        controls={false}
                        muted={true}
                        url={VideoProjects} >
                      </ReactPlayer>
                    </motion.div>
                  }
                </AnimatePresence>
                <div className="absolute z-10 clippathProjects top-0 left-0 xl:w-64 xxl:w-70 xl:h-64 xxl:h-70 bg-white"></div>
                <svg
                  width="0%"
                  height="0%"
                  viewBox="0 0 455.563 493.913"
                >
                  <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                    <path
                      d="M0,0.64 L0.027,0.638,0.056,0.556,0.029,0.549 S0.006,0.507,0.016,0.418 C0.107,0.36,0.109,0.292,0.109,0.292 C0.109,0.26,0.115,0.107,0.138,0.111 S0.2,0.107,0.208,0.111 V0.059 A0.306,0.422,0,0,0,0.299,0.095 C0.348,0.103,0.325,0.075,0.348,0.059 S0.398,0.059,0.412,0.059 C0.42,0.059,0.437,0.086,0.437,0.086 S0.49,0.05,0.497,0.052 C0.512,0.055,0.567,0.044,0.581,0.051 C0.588,0.055,0.607,0.064,0.626,0.073 A0.079,0.109,0,0,0,0.707,0.049 L0.749,0.107 C0.766,0.094,0.783,0.084,0.788,0.082 C0.802,0.079,0.825,0.085,0.825,0.085 S0.911,0.017,0.932,0.021 S0.981,0,0.99,0.003 S1,0.019,0.999,0.031 S0.998,0.071,1,0.083 S0.997,0.118,0.977,0.138 S0.944,0.213,0.936,0.22 S0.949,0.229,0.945,0.248 A0.086,0.118,0,0,1,0.931,0.285 L0.955,0.312 S0.95,0.345,0.939,0.343 S0.942,0.386,0.942,0.386 S0.928,0.444,0.929,0.457 S0.92,0.499,0.906,0.507 S0.903,0.534,0.903,0.548 S0.877,0.58,0.855,0.593 S0.823,0.639,0.818,0.687 C0.818,0.687,0.804,0.723,0.788,0.732 A0.022,0.03,0,0,1,0.785,0.734 C0.774,0.792,0.753,0.881,0.73,0.886 C0.71,0.891,0.518,0.904,0.437,0.928 C0.409,0.936,0.418,0.975,0.418,0.975 L0.369,0.991 A0.316,0.435,0,0,1,0.223,0.991 S0.025,0.878,0.007,0.802 C-0.001,0.768,0,0.64,0,0.64"
                    />
                  </clipPath>
                </svg>
              </div>
            </MainTitle>
          </AppearAnimText>
        </IntersectionObserver>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} style={{ opacity: isBrowser ? displayValueBloc3 : displayValueBloc3Mobile, y: isBrowser ? YPositionValueSpringBloc3 : YPositionValueSpringBloc3Mobile }} className="fixed top-0 w-full z-30">
        <IntersectionObserver>
          <AppearAnimText>
            <MainTitle>
              <div className="relative z-30" id="blocSensi">
                <div className="w-full flex items-center justify-center -mb-5 md:-mb-12 xl:-mb-16 xxl:-mb-24 relative z-10"><div className="w-full md:-mt-5 lg:mt-0 lg:w-3/4"><WritingObserver isReset={true} text="engagée" /></div></div>
                <motion.p className="willChange relative z-20" variants={animation}>à impliquer</motion.p>
                <motion.div className="willChange relative z-20 underline inline-block" variants={animation}>
                  <Link to="/agence/" onMouseEnter={() => toogleHover2(isHoverLink2)} onMouseLeave={() => toogleHover2(isHoverLink2)}>
                    <Linkwrapper mouseIcon="team">
                      toutes les sensibilités
                </Linkwrapper>
                  </Link>
                </motion.div>
                <motion.p className="willChange relative z-20" variants={animation}>et surtout la vôtre.</motion.p>
              </div>
              <motion.div
                className="absolute transform translate-y-48 md:-translate-y-0 overflow-hidden -mt-20 md:-ml-10 xxl:ml-20 md:mt-10 h-full w-auto pointer-events-none bottom-0 left-0 z-20">
                <AnimatePresence>
                  {isMobile &&
                    <motion.div
                      initial="initial"
                      animate="enter"
                      exit="exit"
                      className="absolute z-20 clippathTeam md:top-0 md:left-0 h-40 w-auto md:w-full md:h-full"
                      variants={Transition}
                    >
                      <ReactPlayer
                        width='140%'
                        height='auto'
                        loop={true}
                        playing={true}
                        controls={false}
                        muted={true}
                        url={VideoTeam}
                      >
                      </ReactPlayer>
                    </motion.div>
                  }
                  {isHoverLink2 &&
                    <motion.div
                      initial="initial"
                      animate="enter"
                      exit="exit"
                      className="absolute z-20 clippathTeam top-0 left-0 w-full h-full"
                      variants={Transition}
                    >
                      <ReactPlayer
                        width='140%'
                        height='auto'
                        loop={true}
                        playing={true}
                        controls={false}
                        muted={true}
                        url={VideoTeam}
                      >
                      </ReactPlayer>
                    </motion.div>
                  }
                </AnimatePresence>
                <div className="absolute z-10 w-full clippathTeam h-full bg-white"></div>
                <svg width="100%" height="100%"
                  viewBox="0 0 442.9 471.8"
                >
                  <clipPath id="myClip2" clipPathUnits="objectBoundingBox" >
                    <path
                      d="M0.816,0.16 c-0.036,0.005,-0.183,0.038,-0.2,0.02 c-0.016,-0.017,-0.084,-0.056,-0.115,-0.069 c-0.028,-0.012,-0.056,-0.02,-0.086,-0.027 c-0.029,-0.006,-0.083,-0.032,-0.104,-0.028 c-0.021,0.004,-0.041,0.018,-0.063,0.002 S0.191,0.007,0.18,0.007 S0.034,0.026,0.02,0.051 s0.015,0.131,0.046,0.17 s0.011,0.128,0.021,0.2 s0.042,0.164,0.037,0.186 c-0.004,0.022,0.001,0.044,0.014,0.063 c0.011,0.014,-0.004,0.02,0.02,0.025 c0.024,0.005,0.114,0.02,0.131,0.041 c0.016,0.02,0.071,0.111,0.097,0.123 c0.026,0.012,0.057,0.022,0.086,0.05 s0.064,0.099,0.075,0.095 c0.011,-0.004,0.027,-0.015,0.039,-0.007 s0.085,0,0.102,0.004 c0.017,0.004,0.065,0.01,0.081,-0.001 c0.016,-0.011,0.028,-0.034,0.06,-0.015 s0.085,0.025,0.089,0.022 c0.004,-0.003,0.029,-0.089,0.048,-0.117 c0.019,-0.028,-0.016,-0.051,-0.016,-0.051 s-0.027,-0.078,0.002,-0.127 c0.03,-0.049,0.062,-0.124,0.064,-0.144 c0.001,-0.02,0.007,-0.13,-0.009,-0.145 c-0.015,-0.015,-0.054,-0.02,-0.06,-0.029 c-0.007,-0.008,-0.05,-0.042,-0.058,-0.068 c-0.007,-0.027,-0.017,-0.083,-0.016,-0.093 c0.002,-0.011,0.011,-0.029,0.004,-0.043 s-0.061,-0.029,-0.061,-0.029"
                    />
                  </clipPath>
                </svg>
              </motion.div>
            </MainTitle>
          </AppearAnimText>
        </IntersectionObserver>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} style={{ opacity: isBrowser ? displayValueBloc4 : displayValueBloc4Mobile, y: isBrowser ? YPositionValueSpringBloc4 : YPositionValueSpringBloc4Mobile }} className="fixed top-0 w-full z-30">
        <IntersectionObserver>
          <AppearAnimText>
            <MainTitle>
              <div className="w-full flex items-center justify-center -mb-5 md:-mb-12 xl:-mb-16 xxl:-mb-24 relative z-10"><div className="w-full md:-mt-5 lg:mt-0 lg:w-3/4"><WritingObserver isReset={true} text="engagée" /></div></div>
              <motion.p className="relative z-20 willChange" variants={animation}>parce que votre</motion.p>
              <motion.div className="willChange relative z-20 underline inline-block" variants={animation}><Link to="/contact/"><Linkwrapper mouseIcon="contact">histoire est stratégique</Linkwrapper></Link></motion.div>
              <motion.p className="willChange relative z-20" variants={animation}>et sa création</motion.p>
              <motion.p className="willChange relative z-20" variants={animation}>est émotion.</motion.p>
            </MainTitle>
          </AppearAnimText>
        </IntersectionObserver>
      </motion.div>
      <div>

      </div>
      <motion.div
        initial={{ opacity: 0 }}
        style={{ opacity: isBrowser ? YDisplayValueBloc5 : YDisplayValueBloc5Mobile, y: isBrowser ? YPositionValueBloc5 : YPositionValueBloc5, clipPath: isBrowser ? clipPathString : clipPathStringMobile }}
        onMouseEnter={() => hideCursor()} onMouseLeave={() => showCursor()}
        className="fixed top-0 w-full z-30 bg-fill-green "
      >
        <motion.div style={{ opacity: isBrowser ? opacityBlocEmotion : opacityBlocEmotionMobile }} className="top-0 w-full h-full">
          <MainTitle>
            {isBrowser ?
              <>
                <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>L’émotion </motion.p>
                <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>qui touche est celle</motion.p>
                <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>qui vous raconte.</motion.p>
              </>
              :
              <>
                <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>L’émotion </motion.p>
                <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>qui touche</motion.p>
                <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>est celle</motion.p>
                <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>qui vous raconte.</motion.p>
              </>
            }
          </MainTitle>
        </motion.div>
        <motion.div style={{ y: isBrowser ? YPositionBlocDesignerIdee : YPositionBlocDesignerIdeeMobile }} className="flex items-center justify-center top-0 w-full h-full absolute">
          <MainTitle>
            <div className="w-full flex items-center justify-center">
              <div className="absolute w-70 md:w-90 h-auto -mt-16">
                <WritingObserver reset={true} text="LogoWhite" />
              </div>
            </div>
            <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>Designers</motion.p>
            <motion.p className="willChange relative z-20 font-Gilroy_ExtraBoldItalic stroke-white text-5xl md:text-5xl xl:text-7xl leading-none break-normal" variants={animation}>d’idées</motion.p>
          </MainTitle>
          <div className="transform absolute translate-y-1/2 bottom-0 left-0 w-1/3 z-40">
            <Img
              fluid={data.formGreyEnd.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="forme"
            />
          </div>
        </motion.div>
      </motion.div>
      <div id="wrapperContent" ref={ref} className="w-full flex justify-center items-center bg-white">
        <Smoothscroll isHome={true} className="w-full flex items-center justify-center border-b-2 z-20 flex-col">
          {/* <TextUneAgence /> */}
          <section className="w-full h-screen" style={{ height: WindowHeight * BlocSpace }}></section>
          <SectionProjects data={data} />
          <SectionContact data={data} />
          <FooterLayout />
        </Smoothscroll>
      </div>
      <motion.div style={{ opacity: displayValueBg }} className="fixed top-0 w-full h-screen z-20 bg-gray-fill pointer-events-none"></motion.div>
    </>
  )
}

const ParallaxForms = ({ data, scrollYProgress, scrollY, windowHeightValue }) => {

  const yvalue = useTransform(scrollYProgress, [0, 0.5], [0, -(windowHeightValue + 200)]);
  const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2 })

  const yvalue2 = useTransform(scrollYProgress, [0, 0.4], [0, -(windowHeightValue + 200)]);
  const yspring2 = useSpring(yvalue2, { damping: 20, stiffness: 5, mass: 0.2 })
  // const yvalue2 = useTransform(scrollYProgress, [0, 1], [0, -300]);
  // const yspring2 = useSpring(yvalue2, { damping: 20, stiffness: 5, mass: 0.2, restSpeed: 0.5 })

  // const yvalue3 = useTransform(scrollYProgress, [0, 1], [0, -250]);
  // const yspring3 = useSpring(yvalue3, { damping: 20, stiffness: 5, mass: 0.2, restSpeed: 0.5 })

  //copper bottom 1
  const yvalueCopper = useTransform(scrollYProgress, [0, 0.3], [0, -(windowHeightValue + 200)]);
  const yspringCopper = useSpring(yvalueCopper, { damping: 20, stiffness: 5, mass: 0.2 })

  //waudescher
  const yvalueWaudescher = useTransform(scrollY, [0, windowHeightValue * 2, windowHeightValue * 5.5], [windowHeightValue / 2, windowHeightValue / 2, -(windowHeightValue + 200)]);
  const yvalueSpringWaudescher = useSpring(yvalueWaudescher, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })
  //waudescher Multi
  const yvalueMultiWaudescher = useTransform(scrollYProgress, [0.15, 0.3], [windowHeightValue / 2, -(windowHeightValue + 200)]);
  const yvalueMultiSpringWaudescher = useSpring(yvalueMultiWaudescher, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })
  //mobile
  const YPositionWaudescherMobile = useTransform(scrollY, [windowHeightValue * 1.25, windowHeightValue * 1.5, windowHeightValue * 1.75, windowHeightValue * 2], [windowHeightValue, 0, 0, -windowHeightValue]);
  const YPositionWaudescherSpringMobile = useSpring(YPositionWaudescherMobile, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  //bloc4
  const YPositionValueCopper2 = useTransform(scrollY, [windowHeightValue * 5, windowHeightValue * 12.5], [200, -windowHeightValue]);
  const YPositionValueSpringCopper2 = useSpring(YPositionValueCopper2, { damping: 15, stiffness: 10, mass: 0.2 })
  //whiteform
  const YPositionValueWhite2 = useTransform(scrollY, [windowHeightValue * 6, windowHeightValue * 13], [windowHeightValue / 2, -windowHeightValue]);
  const YPositionValueSpringWhite2 = useSpring(YPositionValueWhite2, { damping: 15, stiffness: 10, mass: 0.2 })
  //grey
  const YPositionValueGrey2 = useTransform(scrollY, [windowHeightValue * 6, windowHeightValue * 13], [windowHeightValue / 2, -(windowHeightValue + 200)]);
  const YPositionValueSpringGrey2 = useSpring(YPositionValueGrey2, { damping: 15, stiffness: 10, mass: 0.2 })

  const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 100]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2, restSpeed: 0.5 })

  return (
    <>
      <motion.div
        transition={{ duration: 0.1 }}
        id="form-silver-top"
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti w-70 fixed pointer-events-none top-0 right-0 -mt-12 z-30">
        <Img
          fluid={data.formTopRight.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <div className="w-3/4 fixed pointer-events-none h-40 z-30 flex items-start justify-center">
        <motion.div
          transition={{ duration: 0.1 }}
          style={{ y: isBrowser ? yspring2 : yspringMobile }}
          className="forme_opti w-12 pointer-events-none mt-16 top-0 left-0 z-30">
          <Img
            fluid={data.formTopCenterSilver.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="forme"
          />
        </motion.div>
        <motion.div
          transition={{ duration: 0.1 }}
          style={{ y: isBrowser ? yspring : yspringMobile }}
          className="forme_opti w-20 pointer-events-none -mt-3 top-0 left-0 ml-70 z-30">
          <Img
            fluid={data.formTopCenterBlue.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="forme"
          />
        </motion.div>
      </div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className=" fixed w-64 ml-32 mb-20 h-screen-1/2 forme_opti pointer-events-none bottom-0 left-0 z-30">
        <Img
          fluid={data.formCenterGreen.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring2 : yspringMobile }}
        className=" fixed w-16 ml-whiteForm mt-20 h-screen-1/2 forme_opti pointer-events-none bottom-0 left-0 z-30">
        <Img
          fluid={data.formCenterWhite.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
          className="mt-10"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspringCopper : yspringMobile }}
        className="fixed w-32 pointer-events-none bottom-0 right-0 mr-wide z-30">
        <Img
          fluid={data.formBottomCopper.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring2 : yspringMobile }}
        className="fixed w-12 -mb-5 pointer-events-none bottom-0 right-0 mr-whiteformbottom z-30">
        <Img
          fluid={data.formBottomWhite.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yvalueMultiSpringWaudescher : YPositionWaudescherSpringMobile }}
        className="fixed w-70 -mb-5 pointer-events-none bottom-0 left-0 ml-64 z-30">
        <Img
          fluid={data.formGreyWaudescher.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yvalueSpringWaudescher : YPositionWaudescherSpringMobile }}
        className="fixed w-32 -mb-5 pointer-events-none bottom-0 left-0 ml-48 z-30">
        <Img
          fluid={data.formMultiWaudescher.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? YPositionValueSpringCopper2 : YPositionWaudescherSpringMobile }}
        className="fixed w-12 pointer-events-none bottom-0 ml-40 left-0 mr-wide z-30">
        <Img
          fluid={data.formBottomCopper2.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? YPositionValueSpringWhite2 : YPositionWaudescherSpringMobile }}
        className="fixed w-60 xxl:w-70 ml-whiteForm h-screen-1/2 mr-0 xxl:mr-40 forme_opti pointer-events-none bottom-0 right-0 z-40">
        <Img
          fluid={data.formCenterWhite2.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
          className="mt-10"
        />
      </motion.div>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? YPositionValueSpringGrey2 : YPositionWaudescherSpringMobile }}
        className=" fixed w-16 -mb-24 mr-wide h-screen-1/2 forme_opti pointer-events-none bottom-0 right-0 z-30">
        <Img
          fluid={data.formGreyLittle.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
          className="mt-10"
        />
      </motion.div>

    </>
  )
}

const SectionProjects = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  //console.log('SectionProjects.posts', posts)

  return (
    <>
      <section className="w-full md:w-4/5 mt-10 md:mt-20 md:mb-20">
        <div className="w-full flex items-center justify-center ">
          <div className="flex mb-10 md:mb-20 items-center justify-center flex-col w-full md:w-1/2 text-center relative font-Gilroy_ExtraBoldItalic text-7xl">
            <p>nos</p>
            <div className="w-full lg:w-3/4 -mt-5 md:-mt-10" ><WritingObserver isReset={false} text="histoires_footer" /></div>
            <div className="absolute left-0 top-0 w-40 transform translate-y-1/2">
              <Img
                fluid={data.formBgNosHistoires.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="forme"
              />
            </div>
          </div>
        </div>
        <section
          className="flex flex-col lg:flex-row flex-wrap w-full pt-10 pb-10 md:pt-0 lg:pb-20 row-gap-20 lg:row-gap-0"
        >
          {posts.slice(0, 6).map((post) => (
            post.node.frontmatter.path !== null &&
            <div key={post.node.frontmatter.path} className="flex project-grid items-start justify-center w-full lg:w-1/3">
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex flex-col justify-between list-none w-9/12 relative ">
                <WebglWrapperHome data={data} post={post} iteration={post.node.frontmatter.title} />
              </motion.li>
            </div>
          ))}
        </section>
        <div className="w-full mt-10 lg:mt-0 flex">
          <div className="w-0 md:w-3/4"></div>
          <div className="flex relative overflow-visible w-full lg:mr-20 lg:w-2/4 items-center justify-center  lg:items-end lg:justify-end text-black tracking-normal">
            <Link to="/projets/">
              <Linkwrapper mouseIcon="discover" className="flex flex-row items-center justify-center gap-2">
                <div className="relative z-40 flex items-center justify-center flex-row gap-4"><span className="text-3xl font-Gilroy_ExtraBoldItalic tracking-normal">Plus d'histoires </span><span className="text-6xl font-Gilroy_extrabold">+</span></div>
              </Linkwrapper>
            </Link>
            <div className="absolute right-0 h-full z-50 top-0 w-20 transform translate-x-5 pointer-events-none">
              <Img
                fluid={data.formBgPlus2.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="forme"
              />
            </div>
            <div className="absolute w-75 z-30 -mr-20 transform translate-x-1/2 -translate-y-1/2 right-0 top-0">
              <Img
                fluid={data.formBgPlus.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="forme"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const IntroBtn = ({ scrollToContentValue, windowHeightValue }) => {

  const [OpacityTransition, setOpacityTransition] = useState(0);
  const { scrollY } = useViewportScroll();

  const yvalue = useTransform(scrollY, [400, 600, scrollToContentValue - (windowHeightValue * 1.5), scrollToContentValue], [400, 0, 0, 400]);
  const yspring = useSpring(yvalue, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  const yvalueMobile = useTransform(scrollY, [50, 70, scrollToContentValue - (windowHeightValue * 1.2), scrollToContentValue - windowHeightValue], [400, 0, 0, 400]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  function scrollToContent() {
    setOpacityTransition(1);
    window.setTimeout(() => window.scrollTo({ top: scrollToContentValue, behavior: 'smooth' }), 500);
    window.setTimeout(() => setOpacityTransition(0), 1500);
  }

  return (
    <>
      <motion.div className="fixed w-full h-full z-50 bg-white pointer-events-none" animate={{ opacity: OpacityTransition }} transition={{ type: "tween", duration: 0.250 }} ></motion.div>
      <motion.div
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="flex bottom-0 uppercase font-Gilroy_bold justify-center mb-4 md:mb-6 items-center w-screen h-12 fixed z-50 text-xs md:text-xs tracking-widest invert text-white">
        <div className=" cursor-pointer p-2" onClick={() => scrollToContent()}>
          Passer l'intro
      </div>
      </motion.div>
    </>
  )
}

const SectionContact = ({ data }) => {

  const dataImg = data;

  return (
    <>
      <section className="relative z-30 w-3/4 flex items-center justify-center my-20">
        <Link to="/contact/">
          <Linkwrapper mouseIcon="contact">
            <div className="flex flex-col text-center items-center ">
              <h1 className="font-Gilroy_ExtraBoldItalic stroke text-4xl md:text-4xl xl:text-6xl leading-none break-normal">Vous souhaitez partager</h1>
              <div className="w-4/5 md:w-2/3 -mt-3 lg:-mt-6"><WritingObserver reset={true} text="UneHistoire" /></div>
            </div>
          </Linkwrapper>
        </Link>
      </section>
    </>
  )
}

const TextUneAgence = () => {

  return (
    <>
      <div className="sticky top-0" >
        <IntersectionObserver>
          <AppearAnimText>
            <MainTitle>
              <motion.p variants={animation}>Une agence</motion.p>
              <motion.p variants={animation}>humainement</motion.p>
              <motion.p variants={animation}>passionnée,</motion.p>
              <motion.p variants={animation}>émotionnellement</motion.p>
              {/* <TitleEngageeObserver /> */}
            </MainTitle>
          </AppearAnimText>
        </IntersectionObserver>
      </div>
    </>
  )
}

export default connect(state => ({
  scrollHeight: state.Scrollvalue.scrollHeight,
  MousePositionX: state.MousePosition.x,
  MousePositionY: state.MousePosition.y
}), null)(IndexPage);

export const indexPageQuery = graphql`
  query IndexPage {
     coverProjects: file(relativePath: { eq: "content/homepage/projects_img.jpg" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
     },
     formTopRight: file(relativePath: { eq: "UI/formes/index/top-right-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
     },
     formTopCenterBlue: file(relativePath: { eq: "UI/formes/index/forme_middle-blue-1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formTopCenterSilver: file(relativePath: { eq: "UI/formes/index/forme_middle-silver-1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formCenterGreen: file(relativePath: { eq: "UI/formes/index/forme_middle-green-1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formCenterWhite: file(relativePath: { eq: "UI/formes/index/forme_middle-white-1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formBottomCopper: file(relativePath: { eq: "UI/formes/index/forme_bottom-copper-1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formBottomCopper2: file(relativePath: { eq: "UI/formes/index/forme_bottom-copper-2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formBottomWhite: file(relativePath: { eq: "UI/formes/index/forme_bottom-white-1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formWaudescher: file(relativePath: { eq: "UI/formes/index/forme_waudescher.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formGreyWaudescher: file(relativePath: { eq: "UI/formes/index/forme_grey_waudescher.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formMultiWaudescher: file(relativePath: { eq: "UI/formes/index/forme_multi_waudescher.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formCenterWhite2: file(relativePath: { eq: "UI/formes/index/forme_middle-white-2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formGreyLittle: file(relativePath: { eq: "UI/formes/index/forme_middle-grey-little.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formGreyEnd: file(relativePath: { eq: "UI/formes/index/forme_grey_bottom_end.png" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formBgNosHistoires: file(relativePath: { eq: "UI/formes/index/bg-histoires.png" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formBgPlus: file(relativePath: { eq: "UI/formes/index/forme_grey_bg_plus.png" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
     formBgPlus2: file(relativePath: { eq: "UI/formes/index/bg-plus.png" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
     },
    allMarkdownRemark
      (
      filter: {fileAbsolutePath: {regex: "/(projets)/"  }}
      sort: { fields: [frontmatter___date], order: DESC }
      )
    {
      edges {
        node {
          frontmatter {
            nouveau
            date
            titre
            path
            description
            tags
            cover { 
                publicURL
                childImageSharp {
                  fluid(quality: 95, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            video {
              publicURL
            }
          }
        }
      }
    }
  }
`
