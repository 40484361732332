import React from 'react';

const MainTitle = ({ children }) => {
    return ( 
        <>
        <article className="flex h-screen w-full items-center justify-center">
        <section className="px-5 w-full md:w-3/4 text-black justify-center leading-none text-center font-Gilroy_BlackItalic text-4xl md:text-5xl lg:text-7xl relative">
        {children}
        </section>
        </article>
        </>
    )
}

export default MainTitle